import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";


export const systemService = {
  getCommissionedDevices,
  getMetrics,
  getTotalMetrics,
  getSystemAdmins,
  getLicenseSupportEngineers,
  getUsersForOrganisation,
  inviteUser
};


function getCommissionedDevices(callback, reject) {
  return SuperAgent
  .get(api_root + "/system/machines")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}



function getMetrics(callback, reject) {
  return SuperAgent
  .get(api_root + "/system/metrics")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function getTotalMetrics(callback, reject) {
  return SuperAgent
  .get(api_root + "/system/total_metrics")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getSystemAdmins(callback, reject) {
  return SuperAgent
  .get(api_root + "/system/users/system_admins")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getLicenseSupportEngineers(callback, reject) {
  return SuperAgent
  .get(api_root + "/system/users/license_support_engineers")
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}

function getUsersForOrganisation(organisation_id, callback, reject) {
  return SuperAgent
  .get(api_root + `/system/users/${organisation_id}`)
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}


function inviteUser(organisation_id, email, user_type, callback, reject) {
  return SuperAgent
  .post(api_root + `/system/users/create`)
  .send({organisation_id})
  .send({email})
  .send({user_type})
  .withCredentials()
  .set('Authorization', getBearerAccessToken())
  .use(unauthorizedRedirect)
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
  .catch(errorHandling)
}
