import SuperAgent from 'superagent'
import {api_root} from 'index.js'
import {errorHandling, unauthorizedRedirect} from './error.handler.js'
import {getBearerAccessToken} from "./authenticate";


export const loginService = {
  getLoginMethod,
};


function getLoginMethod(email, callback, reject) {
  return SuperAgent
  .post(api_root + "/login_method")
  .send({email})
  .withCredentials()
  .then((response) => {
    const text = response.text;
    const data = text && JSON.parse(text);
    callback(data)
  })
  .catch(reject)
}
